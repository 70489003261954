<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'CSR',

    metaInfo: {
      title: 'CORPORATE SOCIAL RESPONSIBILITY',
      titleTemplate: 'Howellcare Industries Sdn Bhd CSR',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'CORPORATE SOCIAL RESPONSIBILITY, HUMAN RIGHTS, HEALTH AND SAFETY, BUSINESS ETHICS AND COMPLIANCE, ENVIRONMENTAL STEWARDSHIP, COMMUNITY INVOLVEMENT, nitrile, glove, manufacturing, products, international' },
        { vmid: 'description', name: 'description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'csr-main',
        'csr-statement',
        'before-footer',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'csr',
      },
    },
  }
</script>
